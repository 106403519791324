import React, { useState, MouseEvent } from 'react'
import './App.css';
import projectList from './ProjectDB.json'

function App() {

  const [isShown, setIsShown] = useState(false);
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [titleHover, setTitleHover] = useState(false)

  const handleMouseEvent = (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault()
      console.log(e.pageX)
      setX(e.pageX)
      setY(e.pageY)
  }
  function handleMouseLeave() {
      setTitleHover(false)
      console.log("Mouse Exit")
  }
  function handleMouseEnter() {
      setTitleHover(true)
      console.log("Mouse Enter")
  }

  return (
    <div className="App">
      <div id="bgConst" className="h-screen w-full p-6 overflow-x-hidden overflow-y-auto meshBG font-stratos">
        <div id="container" className="grid grid-cols-12 auto-rows-auto h-full">
          <div id="logo" className='col-start-2 col-end-6 text-center max-h-16 mt-8 mb-32  md:col-start-10 md:col-end-12'>
            <img src={require('./images/logo.png')} width={200} alt="Studio Forage"></img>
          </div>
          <div id="introContainer" className="col-start-2 col-end-12 row-start-2 text-left sm:col-end-9">
              <div id="introText" className="align-top text-2xl leading-relaxed text-white md:text-4xl md:leading-snug">
              Studio Forage is an experimental design collective that explores emerging technologies, life in cities and alternate realities through exhibitions, installations, and writing. 
              </div>
              {/* <div id="bar" className='align-bottom py-16'>
                  <hr
                  style={{
                      color: '#ffffff',
                      backgroundColor: '#ffffff',
                      height: 5,
                      width: "75%"
                        }}
                    />
              </div> */}
          </div>
          <div id="holdContainer" className="col-start-2 col-end-12 row-start-4 text-left pt-16 md:pt-4 sm:col-end-9">
            <div id="holdText" className="align-bottom text-l leading-8 text-white md:text-xl">
              Right now, we’re taking some time to think about what comes next for us – check back soon.
            </div>
          </div>

          {/* <div id="workContainer" className="col-start-2 col-end-7 text-left row-end-auto text-white text-xl">
            <div id="work" className="py-6 flex">
                <div className="flex-auto W-64 h-24">
                <img src={require('./images/StudioForage-Alt60-01.jpg')} alt="Studio Forage"></img>
                </div>

            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default App;
